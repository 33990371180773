import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Box, Typography } from '@mui/material';

import { PlayButton } from '../../components/button';
import PodcastBadges from '../../components/PodcastBadges';
import Section from '../../components/section';
import RelatedDownloads from '../../components/RelatedDownloads';
import Markdown from '../../components/Markdown';

const Episode = (props) => {
  const { episode } = props;
  const hasRelatedDownloads = props.relatedDownloads.nodes.length > 0;

  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
          <Box
            component={GatsbyImage}
            mb={2}
            image={episode.itemImage.childImageSharp.gatsbyImageData}
            alt={episode.item.title}
          />
          <PlayButton src={episode.item.enclosure.url} size="small" />
          <PodcastBadges mp3File={episode.item.enclosure.url} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" sx={{ mt: 0 }}>
            {episode.item.isoDate}
          </Typography>
          <Typography variant="h1" sx={{ mt: 0 }}>
            {episode.item.title}
          </Typography>
          <Typography variant="h2">{episode.item.itunes.subtitle}</Typography>
          {hasRelatedDownloads && (
            <RelatedDownloads relatedDownloads={props.relatedDownloads} />
          )}
          <Markdown>{episode.item.content}</Markdown>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Episode;
