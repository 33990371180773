import React from 'react';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

import Link from '@mui/material/Link';

import { PlayButton, BackButton } from '../components/button/button';
import CallToAction from '../sections/CallToAction';
import ContactDetails from '../sections/contactDetails';
import Episode from '../sections/episode';
import Hero, { Text, Tagline, Title } from '../sections/hero/hero';
import Layout from '../components/layout/layout';

export default function PodcastEpisode(props) {
  const backgroundImage = props.data.file.childImageSharp.gatsbyImageData;
  const episode = props.data.podcastRssFeedEpisode;

  return (
    <Layout
      title={episode.item.title}
      tagline={`${episode.item.title} – ${episode.item.itunes.subtitle}.`}
      description="Höre diese Ausgabe jetzt hier oder bei Google, Apple, Spotify & Co."
      shareImage={getSrc(episode.shareImage.childImageSharp.gatsbyImageData)}
      shareImageWidth={episode.shareImage.childImageSharp.gatsbyImageData.width}
      shareImageHeight={
        episode.shareImage.childImageSharp.gatsbyImageData.height
      }
      pathname={props.location.pathname}
    >
      <Hero
        backgroundImage={backgroundImage}
        backgroundColor=""
        darkMode="true"
      >
        <BackButton />
        <Tagline>{episode.item.title}</Tagline>
        <Title>{episode.item.itunes.subtitle}</Title>
        <Text>
          Diese Ausgabe entweder hier, bei{' '}
          <Link
            href="https://podcasts.apple.com/de/podcast/digital-insurance-podcast/id1498593777"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple
          </Link>
          ,{' '}
          <Link
            href="https://podcasts.google.com/?feed=aHR0cDovL3BvZGNhc3QtYWRtaW4uZmlyZWJhc2VhcHAuY29tL2ZlZWQvMlQ5YVZxRUpodmQxRmNrMnZWUHAv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google
          </Link>
          ,{' '}
          <Link
            href="https://open.spotify.com/show/0Oh8uJFbxX066lBPdCGehW"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify
          </Link>{' '}
          oder in deiner Lieblingsapp hören.
        </Text>
        <PlayButton src={episode.item.enclosure.url} />
      </Hero>

      <Episode
        episode={episode}
        relatedDownloads={props.data.relatedDownloads}
      />

      <CallToAction
        title="Du willst Gast im Podcast sein?"
        text="
            Wenngleich wir viele Anfragen erhalten, suchen wir laufend spannende Gesprächspartner.
            Am liebsten reden wir über starke Meinungen, die für den digitalen Wandel in der Versicherungswirtschaft von Bedeutung sind.
            Lass uns einen Termin vereinbaren und gemeinsam schauen, ob dein Thema für unsere Zuhörer interessant ist!"
        label="Termin vereinbaren"
        href="https://calendar.app.google/nor372ehN7T1wF1x6"
        target="_blank"
      />
      <ContactDetails
        title="Fragen, Kritik oder Anregungen? Wir wollen es hören!"
        profiles={props.data.team.nodes}
      />
    </Layout>
  );
}
export const query = graphql`
  query ($guid: String!) {
    podcastRssFeedEpisode(item: { guid: { eq: $guid } }) {
      shareImage: itemImageFile {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            height: 628
            backgroundColor: "#ffffff"
            placeholder: BLURRED
            transformOptions: { fit: CONTAIN }
            layout: FIXED
          )
        }
      }
      itemImage: itemImageFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      item {
        content
        isoDate(formatString: "DD.MM.YYYY")
        title
        itunes {
          subtitle
          image
        }
        enclosure {
          url
        }
      }
    }
    file(relativePath: { eq: "backgrounds/bg-podcast-episode.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    relatedDownloads: allContentfulPaper(
      filter: { relatedPodcasts: { in: [$guid] } }
    ) {
      nodes {
        callToAction
        slug
        title
      }
    }
    team: allTeamJson(
      filter: { unit: { eq: "podcast" } }
      sort: { order: ASC, fields: prio }
    ) {
      nodes {
        name
        bio
        focus
        city
        country
        bookingLink
        linkedin
        email
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
