import React from 'react';

import { Grid } from '@mui/material';

import Section from '../../components/section';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import TeamProfile from '../../components/teamProfile';

export default function ContactDetails({ title, profiles }) {
  return (
    <Section>
      <Grid container spacing={2}>
        {title && <SectionTitle>{title}</SectionTitle>}
        {profiles.map((profile, i) => (
          <Grid
            item
            key={i}
            xs={12}
            sm={24 / profiles.length}
            md={12 / profiles.length}
          >
            <TeamProfile
              profile={profile}
              contactOnly
              singleProfile={profiles.length === 1 ? true : false}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}
