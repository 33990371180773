import React from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';

export default function Section({ relatedDownloads }) {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        padding: 2,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="h5">Dokumente zu dieser Ausgabe</Typography>
      {relatedDownloads.nodes.map(({ callToAction, slug, title }) => {
        return (
          <Grid container spacing={0} sx={{ mt: 1 }}>
            <Grid item xs={12} md={8} sx={{ margin: 'auto' }}>
              <Typography variant="body1" sx={{ mt: 0 }}>
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ textAlign: { xs: 'left', md: 'right' } }}
            >
              <Button
                sx={{ marginLeft: { xs: -1, md: 0 } }}
                onClick={() => {
                  window.location.href = `/consulting/papers/${slug}`;
                }}
              >
                {callToAction}
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
