import React from 'react';
import Box from '@mui/material/Box';

import ApplePodcastBadge from '../../assets/images/podcast-badges/DE_Apple_Podcasts_Listen_Badge_RGB.svg';
import SpotifyPodcastBadge from '../../assets/images/podcast-badges/spotify-podcast-badge-blk-grn-165x40.svg';
import RSSPodcastBadge from '../../assets/images/podcast-badges/subscribe-on-rss.svg';
import Mp3DownloadBadge from '../../assets/images/podcast-badges/download-mp3.svg';

export default function Badges(props) {
  const badges = [
    {
      imageSrc:
        'https://www.gstatic.com/podcasts_console/promote/German_DE/DE_Google_Podcasts_Badge.svg',
      imageAlt: 'Anhören auf Google Podcasts',
      href: 'https://podcasts.google.com/feed/aHR0cDovL3BvZGNhc3QtYWRtaW4uZmlyZWJhc2VhcHAuY29tL2ZlZWQvMlQ5YVZxRUpodmQxRmNrMnZWUHAv',
    },
    {
      imageSrc: ApplePodcastBadge,
      imageAlt: 'Anhören auf Apple Podcast',
      href: 'https://podcasts.apple.com/de/podcast/digital-insurance-podcast/id1498593777',
    },
    {
      imageSrc: SpotifyPodcastBadge,
      imageAlt: 'Anhören auf Spotify Podcast',
      href: 'https://open.spotify.com/show/0Oh8uJFbxX066lBPdCGehW',
    },
    {
      imageSrc: RSSPodcastBadge,
      imageAlt: 'Subscribe via RSS Feed',
      href: 'https://podcast-admin.firebaseapp.com/feed/2T9aVqEJhvd1Fck2vVPp/',
    },
    {
      imageSrc: Mp3DownloadBadge,
      href: props.mp3File,
      imageAlt: 'Als MP3-Datei herunterladen',
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {badges
        .filter((badge) => {
          return 'href' in badge;
        })
        .map((badge) => (
          <Box
            sx={{
              padding: 0,
              textAlign: 'center',
              img: {
                width: 160,
                padding: 0,
                marginBottom: 0.5,
              },
            }}
          >
            <a
              href={badge.href}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img alt={badge.imageAlt} src={badge.imageSrc} />
            </a>
          </Box>
        ))}
    </Box>
  );
}
