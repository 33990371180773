import React from 'react';
import { Typography, Grid } from '@mui/material';

import Button from '../../components/button';
import Section from '../../components/section';

/**
 *
 * @param {*} title The section's title
 * @param {*} text The section's main text
 * @param {*} href  The url of the call to action button
 * @param {*} label The text of the call to action button
 * @param {*} target The opening target of the call to action button
 * @returns
 */
export default function CallToAction({ title, text, href, label, target }) {
  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
          <Typography>{text}</Typography>
        </Grid>
        <Grid container item md={4} justifyContent="center" alignItems="center">
          <Button href={href} target={target || '_self'}>
            {label}
          </Button>
        </Grid>
      </Grid>
    </Section>
  );
}
